<template>
	<div class="login">
		<vue-headful
			title="Log In - UNDERMRKT"
			description="Description from vue-headful"
		/>
		<div class="container">
			<div class="card">
				<div class="login__title">
					<font-awesome-icon :icon="['fa', 'unlock']" />
					{{ $t("login.forgot_title") }}
				</div>
				<div v-if="error" class="alert alert-danger">{{ error }}</div>
                                <div v-if="email_sent" class="alert alert-success">{{ $t("login.reset_email_sent") }} <strong>{{email}}</strong></div>
                                
				<div class="form-group" v-show="!email_sent">
					<label for="email">Email</label>
					<div class="input-icon">
						<font-awesome-icon :icon="['fa', 'envelope']" />
						<input
							id="email"
							type="email"
							class="form-control"
							name="email"
							value
							required
							autofocus
							v-model="email"
						/>
					</div>
				</div>
				<div class="form-group">
					<button-primary :onClick="sendResetEmail" v-show="!email_sent">
						<span v-if="!isLoading">
							{{ $t("login.send_reset_email") }}</span
						>
						<font-awesome-icon
							v-if="isLoading == true"
							:icon="['fas', 'circle-notch']"
							spin
						/>
					</button-primary>
					<div class="separator" v-show="!email_sent">
						<span>
							or
						</span>
					</div>
					<router-link
						:to="{ name: 'login' }"
						class="button secondary"
					>
						<span v-if="!isLoading">
							<font-awesome-icon :icon="['fa', 'arrow-left']" />
							{{ $t("login.login") }}
						</span>
						<font-awesome-icon
							v-if="isLoading == true"
							:icon="['fas', 'circle-notch']"
							spin
						/>
					</router-link>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import firebase from "firebase/app";
import "firebase/auth"; // for authentication
import "firebase/storage"; // for storage
import "firebase/firestore"; // for cloud firestore
import "firebase/functions"; // for cloud functions
import { mapMutations } from "vuex";
import UAParser from "ua-parser-js";

export const db = firebase.firestore();
export default {
	data() {
		return {
			isLoading: false,
			email: "",
			error: null,
                        data: [],
                        email_sent:false,
		};
	},
	methods: {
		sendResetEmail() {
			var auth = firebase.auth();
			var emailAddress = this.email;

			auth.sendPasswordResetEmail(emailAddress)
				.then(() => {
                                        this.email_sent = true
				})
				.catch((error) => {
					this.error = error;
				});
		},
	},
	mounted() {

	},
	beforeCreate: function() {
		document.body.className = "login";
	},
};
</script>
<style lang="scss" scoped>
@import "@/scss/_global.scss";

body {
	background: #f00;
	background-size: cover;
	background-position: center;
}

.button {
	width: 100%;
}

.login .card {
	margin: 100px auto;
	width: 360px;
	padding: 20px;
	box-shadow: 0px 0px 20px 0px rgba(#000, 0.1);
	max-width: 100%;
	box-sizing: border-box;
	max-width: 100%;
}

.login {
	&__links {
		font-size: 14px;
		color: #424242;
		text-align: center;
		padding: 15px 0px;

		a {
			color: #000;
			font-weight: bold;
		}
	}

	&__title {
		font-size: 22px;
		margin-bottom: 25px;
		padding-bottom: 15px;
		border-bottom: 1px solid #e7e7e7;
		text-align: center;
		color: #666;
		display: flex;
		align-items: center;
		justify-content: center;
		svg {
			height: 16px;
			width: 16px;
			margin-right: 5px;
			margin-bottom: 2px;
			color: #14b11a;
		}
	}
}
</style>
