<template>
  <div class="regisetr">
    <vue-headful
      title="Create account - UNDERMRKT"
      description="Description from vue-headful"
    />
    <div class="container">
      <div class="card">
        <div class="login__title" v-show="!initialLoading">
          <span>
            <span>
              <font-awesome-icon :icon="['fa', 'user-plus']" />
              {{ $t("register.create_account") }}
            </span>
          </span>
        </div>
        <div v-if="error" class="alert alert-danger">{{ error }}</div>
        <div class="step step_1 current" v-if="step == 1">
          <template v-if="initialLoading">
            <div class="initial-loader">
              <font-awesome-icon :icon="['fas', 'circle-notch']" spin />
            </div>
          </template>
          <template v-else>
            <div class="form-group">
              <label for="email">Email</label>
              <div class="input-icon">
                <font-awesome-icon :icon="['fa', 'envelope']" />
                <input
                  id="email"
                  type="email"
                  class="form-control"
                  name="email"
                  value
                  required
                  autofocus
                  v-model="email"
                />
              </div>
            </div>
            <div class="form-group">
              <label for="password"> {{ $t("login.password") }}</label>
              <div class="input-icon">
                <font-awesome-icon :icon="['fa', 'lock']" />
                <input
                  id="password"
                  type="password"
                  class="form-control"
                  name="password"
                  required
                  v-model="password"
                />
              </div>
            </div>
            <div class="form-group">
              <label for="password"> {{ $t("login.password_confirm") }}</label>
              <div class="input-icon">
                <font-awesome-icon :icon="['fa', 'lock']" />
                <input
                  id="password_confirm"
                  type="password"
                  class="form-control"
                  name="password_confirm"
                  required
                  v-model="password_confirm"
                />
              </div>
            </div>
            <button-primary
              :disabled="
                !email ||
                  !password ||
                  (password && password != password_confirm)
              "
              :onClick="RegisterWithEmail"
              :class="'g-recaptcha complete-registration'"
              :id="'sign-in-button'"
            >
              <span v-if="!isLoading">{{ $t("login.create_account") }}</span>
              <font-awesome-icon
                v-if="isLoading == true"
                :icon="['fas', 'circle-notch']"
                spin
              />
            </button-primary>
            <div class="separator">
              <span> or </span>
            </div>
            <button-primary
              :onClick="AuthWithGoogle"
              :class="'g-recaptcha complete-registration google'"
              :id="'sign-in-button'"
            >
              <span class="google-bg" v-if="!isLoading">
                <img src="@/assets/google_logo.svg" width="20" alt="" />
              </span>

              <span v-if="!isLoading"> Sign Up with Google </span>
              <font-awesome-icon
                v-if="isLoading == true"
                :icon="['fas', 'circle-notch']"
                spin
              />
            </button-primary>
            <div class="login__links">
              {{ $t("register.have_account") }}
              <router-link to="/login">{{ $t("register.login") }}</router-link>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div id="recaptcha-container"></div>
  </div>
</template>
<script>
import firebase from "firebase/app";
import "firebase/auth"; // for authentication
import "firebase/storage"; // for storage
import "firebase/firestore"; // for cloud firestore
import "firebase/functions"; // for cloud functions

import UAParser from "ua-parser-js";
export const db = firebase.firestore();
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      initialLoading: true,
      email: "",
      password: "",
      password_confirm: "",
      device: "",
      geo_data: "",
      isLoading: false,
      phoneInput: "",
      validPhone: false,
      captchaResponse: "",
      codeSent: false,
      smsError: false,
      smsErrorText: "",
      smsCode: "",
      phoneConfirmed: false,
      alreadyIsUser: false,
      dbError: "",
      user: {
        email: null,
        password: null,
      },
      phoneInputProps: {
        dynamicPlaceholder: true,
        wrapperClasses: "",
        inputClasses: "",
        inputId: "phone",
      },
      step: 1,
      error: "",
      finalStepError: "",
    };
  },
  computed: {
    ...mapGetters({
      curUser: "user",
    }),
  },
  methods: {
    RegisterWithEmail() {
      this.isLoading = true;
      firebase
        .auth()
        .createUserWithEmailAndPassword(this.email, this.password)
        .then(async (userCredential) => {
          // Signed in
          var user = userCredential.user;
          this.user.email = user.email;
          var usersRef = db.collection("users").doc(user.uid);
          await usersRef.get().then((docSnapshot) => {
            //If user hash present in DB
            if (docSnapshot.exists) {
              //If email exist say "welcome back"
              this.alreadyIsUser = true;
              this.isLoading = true;
            } else {
              this.completeSignUp(user);
            }
          });
          // ...
        })
        .catch((error) => {
          this.isLoading = false;
          this.error = error;
          var errorCode = error.code;
          var errorMessage = error.message;
          // ..
        });
    },
    AuthWithGoogle() {
      var provider = new firebase.auth.GoogleAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          var credential = result.credential;

          // This gives you a Google Access Token. You can use it to access the Google API.
          var token = credential.accessToken;
          // The signed-in user info.
          var user = result.user;
          this.user.email = user.email;
          /* eslint-disable no-console */
          var usersRef = db.collection("users").doc(user.uid);
          usersRef.get().then((docSnapshot) => {
            //If user hash present in DB
            if (docSnapshot.exists && docSnapshot.data().user_id) {
              //If email exist say "welcome back"
              this.alreadyIsUser = true;

              //Move to homepage
              this.$router.push("home");
            } else {
              this.completeSignUp();
            }
          });
        })
        .catch((error) => {
          this.isLoading = false;
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          // The email of the user's account used.
          var email = error.email;
          // The firebase.auth.AuthCredential type that was used.
          var credential = error.credential;
          // ...
        });
    },
    async geoLocate() {
      async function httpGetAsync(url, callback) {
        var xmlHttp = new XMLHttpRequest();
        xmlHttp.onreadystatechange = function() {
          if (xmlHttp.readyState == 4 && xmlHttp.status == 200)
            callback(xmlHttp.responseText);
        };
        xmlHttp.open("GET", url, true); // true for asynchronous
        xmlHttp.send(null);
      }

      var url =
        "https://ipgeolocation.abstractapi.com/v1/?api_key=977786f851824d68ba98047686ac32a7";

      var result = {};
      await httpGetAsync(url, (response) => {
        this.geo_data = response;
      });
    },
    getDeviceInfo: function() {
      var infoSections = {};
      var parser = new UAParser();
      var userOs = parser.getOS();
      var userDevice = parser.getDevice();
      var userBrowser = parser.getBrowser();

      if (userOs && userOs.name && userOs.version) {
        infoSections["os"] = userOs.name + " " + userOs.version;
      }

      if (userBrowser && userBrowser.name && userBrowser.version) {
        infoSections["browser"] = userBrowser.name + " " + userBrowser.version;
      }

      if (userDevice && userDevice.vendor && userDevice.model) {
        infoSections["device"] = userBrowser.vendor + " " + userBrowser.model;
      } else {
        infoSections["device"] = "N/A";
      }

      if (window) {
        if (window.screen) {
          infoSections["screen_resolution"] =
            window.screen.width + "x" + window.screen.height;
          infoSections["available_screen_space"] =
            window.screen.availWidth + "x" + window.screen.availHeight;
        }

        infoSections["browser_window_size"] =
          window.innerWidth + "x" + window.innerHeight;
        infoSections["device_pixel ratio"] = window.devicePixelRatio;
      }
      this.device = infoSections;
    },
    signOut() {
      this.alreadyIsUser = false;
      this.step = 1;
      firebase
        .auth()
        .signOut()
        .then(() => {
          // this.$router.replace({
          //     name: "login"
          // });
        });
    },
    checkIfAlreadyUser: function() {
      var userId = firebase.auth().currentUser.uid;
      const usersRef = db.collection("users").doc(userId);
      var now = Math.round(+new Date() / 1000);
      usersRef.get().then((docSnapshot) => {
        //If user hash present in DB
        if (docSnapshot.exists) {
          //Check if user entered email before
          if (docSnapshot.get("email")) {
            alert(docSnapshot.get("email"));
            //If email exist say "welcome back"
            this.alreadyIsUser = true;

            //Move to homepage
            this.$router.push("home");
          } else {
            this.isLoading = false;
          }
        } else {
          this.completeSignUp();
        }
      });
    },
    completeSignUp: function(newUser) {
      this.isLoading = true;
      var user = firebase.auth().currentUser;
      if (newUser) {
        user = newUser;
      }
      var currentUserId = user.uid;
      var usersRef = db.collection("users").doc(currentUserId);

      var now = Math.round(new Date() / 1000);

      function reverseString(str) {
        var splitString = str.split("");
        var reverseArray = splitString.reverse();
        var joinArray = reverseArray.join("");
        return joinArray;
      }

      now = reverseString(now.toString());

      var random = Math.floor(Math.random() * 99) + 5;
      now = now * random;
      var user_id = now.toString().substring(0, 7);

      var visitTime = Math.round(+new Date() / 1000);

      var lead_source = "unknown";
      if (localStorage.getItem("lead_source")) {
        lead_source = localStorage.lead_source;
      }

      //Create bootstrap data for new user
      var newUserData = {
        first_visit: visitTime,
        user_id: user_id,
        email: user.email,
        plan: 0,
        lang: this.$i18n.locale,
        group: "test",
        ua_info: this.device,
        geo_data: this.geo_data,
        telegram: false,
        lead_source: lead_source,
      };
      /* eslint-disable no-console */
      // console.log(newUserData);
      usersRef.set(newUserData).then(() => {
        this.$store.dispatch("fetchProfile", newUserData);
        this.$router.push("home");
      });

      //Bootstrap sellers
      var sellersRef = db.collection("sellers").doc(currentUserId);
      sellersRef.set({
        owner: user_id,
        items: [],
      });

      //Bootstrap keywords
      var keywordsRef = db.collection("keywords").doc(currentUserId);
      keywordsRef.set({
        owner: user_id,
        items: [],
      });
    },
    checkIfUserOnLoad() {
      //Check if user is authenicated
      var user = firebase.auth().currentUser;
      if (user) {
        firebase
          .firestore()
          .collection("users")
          .doc(user.uid)
          .get()
          .then((docSnapshot) => {
            console.log(docSnapshot.data());
            //If user hash present in DB
            if (docSnapshot.exists) {
              //Check if user entered email before
              this.$router.push("home");
            } else {
              firebase
                .auth()
                .signOut()
                .then(() => {
                  this.initialLoading = false;
                });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.initialLoading = false;
      }
    },
  },
  mounted() {
    this.geoLocate();
    this.getDeviceInfo();
    this.checkIfUserOnLoad();
  },
  beforeMount: function() {},
  beforeCreate: function() {
    document.body.className = "register";
  },
};
</script>
<style lang="scss" scoped>
.change-number {
  display: block;
  width: 100%;
  text-align: center;

  a {
    color: #000;
    font-weight: 600;
    margin: 20px auto 0px;
    display: inline-block;
    font-size: 13px;
  }
}

.verification-code-input {
  margin: 20px 0px;
}

body {
  background: #f00 !important;
}

.welcome-back {
  text-align: center;
  margin: 15px 0px;
  font-size: 26px;
}

.input {
  background-color: transparent !important;
}

.vue-tel-input {
  height: 56px;
  border: none;
  border-bottom: 2px solid #666 !important;
  background-color: transparent;
  border-radius: 0px;

  .vti__input {
    font-size: 18px !important;
    background: transparent !important;
    padding-left: 20px !important;
    border: none !important;

    &:focus {
    }
  }

  &:focus-within {
    box-shadow: none;
    border-bottom: 2px solid #00a3ff !important;
  }
}

.button {
  width: 100%;
}

.card {
  margin: 100px auto;
  width: 360px;
  padding: 20px;
  box-shadow: 0px 0px 20px 0px rgba(#000, 0.1);
  max-width: 100%;
  box-sizing: border-box;
}

.login {
  &__links {
    font-size: 14px;
    color: #424242;
    text-align: center;
    padding: 15px 0px;

    a {
      color: #000;
      font-weight: bold;
    }
  }

  &__title {
    font-size: 22px;
    margin-bottom: 25px;
    padding-bottom: 15px;
    border-bottom: 1px solid #e7e7e7;
    text-align: center;
    color: #666;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      height: 18px;
      width: 18px;
      margin-right: 5px;
      margin-bottom: 2px;
      color: #00a3ff;
    }
  }
}
</style>
